import {
  BillingHistoryApiModel,
  PaymentResultApiModel,
  PaymentSummaryApiModel,
  StripePaymentIntentionApiModel,
  StripeSubscriptionApiModel,
  SubscriptionApiModel,
  SubscriptionCreatePaymentMethodApiModel,
  SubscriptionCurrencyApiModel,
  SubscriptionCurrentStatusApiModel,
  SubscriptionPaymentMethodApiModel,
  SubscriptionPaymentMethodCardApiModel,
  SubscriptionPaymentTypeApiModel,
  SubscriptionProductApiModel,
  SubscriptionProductIntervalApiModel,
  SubscriptionProductNameApiModel,
  SubscriptionStripeAccountsApiModel,
  SubscriptionUpdateDevicesApiModel,
  SubscriptionUpdateDevicesResultApiModel,
  TrialApiModel,
  VoucherInfoApiModel,
} from 'src/services/subscriptions/apiModels';
import { ValueOf } from 'src/typings/global';

export type BillingHistory = BillingHistoryApiModel;
export type SubscriptionUpdateDevices = SubscriptionUpdateDevicesApiModel;
export type SubscriptionUpdateDevicesResult = SubscriptionUpdateDevicesResultApiModel;
export type Trial = TrialApiModel;
export type { SubscriptionApiModel as Subscription };
export { SubscriptionPaymentTypeApiModel as SubscriptionPaymentType };
export { SubscriptionCurrencyApiModel as SubscriptionCurrency };
export { SubscriptionCurrentStatusApiModel as SubscriptionCurrentStatus };
export { SubscriptionProductIntervalApiModel as SubscriptionProductInterval };
export { SubscriptionProductNameApiModel as SubscriptionProductName };
export type SubscriptionPaymentMethod = SubscriptionPaymentMethodApiModel;
export type SubscriptionCreatePaymentMethod = SubscriptionCreatePaymentMethodApiModel;
export { SubscriptionStripeAccountsApiModel as SubscriptionStripeAccounts };
export type SubscriptionCreatePaymentMethodResult = {
  createPayment: SubscriptionCreatePaymentMethod;
  paymentMethod: SubscriptionPaymentMethod;
};
export type SubscriptionPaymentMethodCard = SubscriptionPaymentMethodCardApiModel;
export type StripePaymentIntention = StripePaymentIntentionApiModel;
export type StripeSubscription = StripeSubscriptionApiModel;
export type PaymentSummary = PaymentSummaryApiModel;
export type PaymentResult = PaymentResultApiModel;

export const SubscriptionState = {
  notStarted: 'notStarted',
  active: 'active',
  upToRenewal: 'upToRenewal',
  pastDue: 'pastDue',
  paymentFailed: 'paymentFailed',
  pendingCancellation: 'pendingCancellation',
  expired: 'expired',
} as const;

export type SubscriptionState = ValueOf<typeof SubscriptionState>;

export const TrialState = {
  available: 'available',
  running: 'running',
  expiring: 'expiring',
  expired: 'expired',
  none: 'none',
} as const;
export type TrialState = ValueOf<typeof TrialState>;

export const SubscriptionProductFullName: Record<SubscriptionProductNameApiModel, string> = {
  plus: 'Airtame Core',
  edu: 'Airtame Core for Education',
  rooms: 'Airtame Hybrid',
} as const;

export type SubscriptionProductFullName = ValueOf<typeof SubscriptionProductFullName>;

export const SubscriptionProductShortName: Record<SubscriptionProductNameApiModel, string> = {
  plus: 'Core',
  edu: 'Core for Education',
  rooms: 'Hybrid',
} as const;

export type SubscriptionProductShortName = ValueOf<typeof SubscriptionProductShortName>;

export const SubscriptionProductLicense = {
  ...SubscriptionProductShortName,
  unlicensed: 'Unlicensed',
} as const;

export type SubscriptionProductLicense = ValueOf<typeof SubscriptionProductLicense>;

export const SubscriptionLicense = {
  core: 'core',
  hybrid: 'hybrid',
} as const;

export type SubscriptionLicense = ValueOf<typeof SubscriptionLicense>;

export const SubscriptionLicenseShortName = {
  core: 'Core',
  hybrid: 'Hybrid',
} as const;

export type SubscriptionLicenseShortName = ValueOf<typeof SubscriptionLicenseShortName>;

export const SubscriptionLicenseState = {
  available: 'available',
  unavailable: 'noLicenseAvailable',
  allUsed: 'allLicensesUsed',
} as const;

export type SubscriptionLicenseState = ValueOf<typeof SubscriptionLicenseState>;

export type SubscriptionProduct = SubscriptionProductApiModel;

export enum SubscriptionStatusType {
  PRISTINE = 'PRISTINE',
  TRIAL = 'TRIAL',
  PAID = 'PAID',
  FREE = 'FREE',
}

export enum CurrencySymbol {
  USD = '$',
  EUR = '€',
}
export type SubscriptionInfoData = {
  products: Record<SubscriptionProductNameApiModel, SubscriptionStatus>;
  subscriptionInfo: SubscriptionInfo | null;
  hasExistingSubscription: boolean;
  isActiveSubscription: boolean;
  isActiveTrial: boolean;
  isCanceledSubscription: boolean;
};

export type PristineSubscriptionStatus = {
  statusType: SubscriptionStatusType.PRISTINE;
};

export type FreeSubscriptionStatus = {
  statusType: SubscriptionStatusType.FREE;
  expirationDate: string;
  wasTrial: boolean;
};
export type TrialSubscriptionStatus = {
  statusType: SubscriptionStatusType.TRIAL;
  name: SubscriptionProductNameApiModel;
  expirationDate: string;
  trialDaysLeft: number;
  startDate: string;
};

export type PaidSubscriptionStatus = Omit<SubscriptionApiModel, 'products' | 'type'> &
  SubscriptionProduct & {
    statusType: SubscriptionStatusType.PAID;
    currencySymbol: CurrencySymbol;
    fullName: SubscriptionProductFullName;
    shortName: SubscriptionProductShortName;
    paymentType: SubscriptionPaymentTypeApiModel;
    // If user creates a credit card subscription while on trial state
    // this converts into a stripe subscription with a trial
    // so they don't lose the remaining trial days
    trialDaysLeft?: number;
    // Property use for a new creation of a subscription where the user needs to choose the billing
    // interval
    discountYearlyPerUnit?: number;
  };

export type SubscriptionStatus =
  | PristineSubscriptionStatus
  | PaidSubscriptionStatus
  | FreeSubscriptionStatus
  | TrialSubscriptionStatus;

export type SubscriptionInfo = Omit<SubscriptionApiModel, 'type'> & {
  paymentType: SubscriptionPaymentTypeApiModel;
};

export type VoucherInfo = VoucherInfoApiModel;

export const VoucherErrorCode = {
  invalidVoucher: 'invalidVoucher',
  voucherAlreadyUsedAnotherOrg: 'voucherAlreadyUsedAnotherOrg',
  voucherAlreadyUsedSameOrg: 'voucherAlreadyUsedSameOrg',
  eduVoucherButNotEduOrg: 'eduVoucherButNotEduOrg',
  nonEduVoucherButEduOrg: 'nonEduVoucherButEduOrg',
  unknownError: 'unknownError',
} as const;

export type VoucherErrorCode = ValueOf<typeof VoucherErrorCode>;
