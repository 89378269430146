import { ValueOf } from 'src/typings/global';

export type SubscriptionApiModel = {
  type: SubscriptionPaymentTypeApiModel;
  status: SubscriptionCurrentStatusApiModel;
  billingInterval: SubscriptionProductIntervalApiModel;
  cancelAt?: string;
  currency: SubscriptionCurrencyApiModel;
  expirationDate: string;
  startDate: string;
  taxPercentage: number;
  products: SubscriptionProductApiModel[];
};

export type SubscriptionUpdateDevicesApiModel = {
  priceId: string;
  devices: number[];
};

export type SubscriptionUpdateDevicesResultApiModel = {
  added: number[];
  removed: number[];
};

export type TrialApiModel = {
  start: string;
  stop: string;
  isActive: boolean;
};

export enum SubscriptionPaymentTypeApiModel {
  CreditCard = 'creditcard',
  Contract = 'contract',
}

export enum SubscriptionCurrentStatusApiModel {
  Active = 'active',
  PastDue = 'past_due',
  Inactive = 'inactive',
  Canceled = 'canceled',
}

export enum SubscriptionProductIntervalApiModel {
  Month = 'month',
  Year = 'year',
}

export enum SubscriptionCurrencyApiModel {
  USD = 'usd',
  EUR = 'eur',
}

export type SubscriptionProductApiModel = {
  priceId: string;
  name: SubscriptionProductNameApiModel;
  unitAmount: number;
  quantity: number;
  assignedSeats: number;
};

export const SubscriptionProductNameApiModel = {
  plus: 'plus',
  edu: 'edu',
  rooms: 'rooms',
} as const;

export type SubscriptionProductNameApiModel = ValueOf<typeof SubscriptionProductNameApiModel>;

type VoucherKindApiModel = 'license' | 'credit';

export type VoucherInfoApiModel = {
  kind: VoucherKindApiModel;
  product: SubscriptionProductNameApiModel;
  months: number;
  quantity: number;
  isActivated: boolean;
  activatedBy?: string;
  activatedAt?: string;
};

export type SubscriptionCreatePaymentMethodApiModel = {
  account: SubscriptionStripeAccountsApiModel;
  paymentMethodId: string;
  taxId: string;
  taxIdType: string;
};

export const SubscriptionStripeAccountsApiModel = {
  eu: 'eu',
  us: 'us',
} as const;

export type SubscriptionStripeAccountsApiModel = ValueOf<typeof SubscriptionStripeAccountsApiModel>;

export type SubscriptionPaymentMethodApiModel = {
  name: string;
  email: string;
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  country: string;
  state?: string;
  taxId: string;
  taxIdType: string;
  company: string;
  card: SubscriptionPaymentMethodCardApiModel;
};

export type SubscriptionPaymentMethodCardApiModel = {
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
};

export type BillingHistoryApiModel = {
  paid: boolean;
  createdDate: string;
  startDate: string;
  expirationDate: string;
  seats: number;
  amount: number;
  currency: SubscriptionCurrencyApiModel;
  invoicePdf: string;
  invoiceUrl?: string;
};

export type PaymentResultApiModel = {
  priceId: string;
  quantity: number;
};

export type PaymentSummaryApiModel = PaymentSummaryTypeApiModel & {
  upcoming?: PaymentSummaryTypeApiModel;
  discount?: PaymentSummaryDiscountApiModel;
};

type PaymentSummaryDiscountApiModel = {
  // coupons will have either an amountOff or percentOff
  amountOff?: number;
  // this is the one we use to compute future renewal price and it comes as "25.5" for "25.5% off"
  // https://stripe.com/docs/api/coupons
  percentOff?: number;
  subtotal: number;
  tax: number;
  total: number;
};

type PaymentSummaryTypeApiModel = {
  // all these fields are expressed in the 100th subdivision of the currency (cents / euro cents)
  amountDue: number;
  balance: number;
  total: number;
  subtotal: number;
  tax: number;
};

export type StripePaymentIntentionApiModel = {
  message: string;
  data: { paymentIntentSecret: string };
};

export type StripeSubscriptionApiModel = {
  message?: string;
  data?: {
    paymentIntentSecret?: string;
  };
};
