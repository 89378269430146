import {
  CurrencySymbol,
  FreeSubscriptionStatus,
  PaidSubscriptionStatus,
  PristineSubscriptionStatus,
  SubscriptionCurrency,
  SubscriptionStatus,
  SubscriptionStatusType,
  SubscriptionStripeAccounts,
  TrialSubscriptionStatus,
} from 'src/typings/Subscription';
import { countriesByCode } from 'src/utils/countries';

/**
 * Check if a product is in pristine status.
 *
 * Pristine status means having a organization that has never started
 * neither trial nor a subscription for a product.
 */
export function isPristineStatus(
  productStatus: SubscriptionStatus
): productStatus is PristineSubscriptionStatus {
  return productStatus.statusType === SubscriptionStatusType.PRISTINE;
}

/**
 * Check if a product is in trial status.
 */
export function isTrialStatus(
  productStatus: SubscriptionStatus
): productStatus is TrialSubscriptionStatus {
  return productStatus.statusType === SubscriptionStatusType.TRIAL;
}

/**
 * Check if a product is in paid status.
 */
export function isPaidStatus(
  productStatus: SubscriptionStatus
): productStatus is PaidSubscriptionStatus {
  return productStatus.statusType === SubscriptionStatusType.PAID;
}

/**
 * Check if a product is in free status.
 *
 * Free status means having a organization that has a product with
 * an inactive trial, inactive subscription or if both are inactive.
 */
export function isFreeStatus(
  productStatus: SubscriptionStatus
): productStatus is FreeSubscriptionStatus {
  return productStatus.statusType === SubscriptionStatusType.FREE;
}

/**
 * Check if a product has a valid subscription.
 *
 * Note that for simplicity we consider a product with valid subscription if
 * the product is either in paid or trial status.
 */
export function isValidSubscription(productStatus: SubscriptionStatus): boolean {
  return isPaidStatus(productStatus) || isTrialStatus(productStatus);
}

/**
 * Get product assigned seats, returns 0 as fallback.
 */
export function getAssignedSeats(product: SubscriptionStatus): number {
  return isPaidStatus(product) ? product.assignedSeats : 0;
}

export function currencyToSymbol(cur: SubscriptionCurrency): CurrencySymbol {
  const currencyMap = Object.entries(CurrencySymbol).reduce(
    (acc, [currency, currencySymbol]) => {
      acc[SubscriptionCurrency[currency]] = currencySymbol;
      return acc;
    },
    {} as Record<string, CurrencySymbol>
  );

  return currencyMap[cur];
}

export function stripeAccount(countryCode: string): SubscriptionStripeAccounts {
  // Countries we sell in $: United States, Canada, US Virgin Islands, US Minor Outlying Islands
  const usDollarCurrencyCountryCodes = [
    countriesByCode.US.id,
    countriesByCode.CA.id,
    countriesByCode.VI.id,
    countriesByCode.UM.id,
  ];

  return usDollarCurrencyCountryCodes.includes(countryCode)
    ? SubscriptionStripeAccounts.us
    : SubscriptionStripeAccounts.eu;
}
