import { RecommendationKey } from 'src/components/routes/Recommendation/types';

const ns = 'recommendation';

export const recommendation = {
  page: `${ns}__page`,
  emptyMessage: `${ns}__empty-message`,
  showDismissedItemsAction: `${ns}__show-dismissed-items-action`,
  getItem: (itemName: RecommendationKey): string => `${ns}__item-${itemName}`,
  getItemIcon: (itemName: RecommendationKey): string => `${ns}__item-${itemName}-icon`,
  getItemTitle: (itemName: RecommendationKey): string => `${ns}__item-${itemName}-title`,
  getItemDevices: (itemName: RecommendationKey): string => `${ns}__item-${itemName}-devices`,
  getItemDismissAction: (itemName: RecommendationKey): string =>
    `${ns}__item-${itemName}-dismiss-action`,
  getItemOpenModalAction: (itemName: RecommendationKey): string =>
    `${ns}__item-${itemName}-open-modal-action`,
  getItemModal: (itemName: RecommendationKey): string => `${ns}__item-${itemName}-modal`,
  getItemModalCloseAction: (itemName: RecommendationKey): string =>
    `${ns}__item-${itemName}-modal-close-action`,
  getItemModalFixIssueAction: (itemName: RecommendationKey): string =>
    `${ns}__item-${itemName}-modal-fix-issue-action`,
};
