import { DeviceOverview } from 'src/typings/DeviceOverview';
import { devicePlatforms, recommendationPriorityMap } from './constants';
import { setDevicePlatforms, updateRecommendation } from './recommendationStore';
import { RecommendationForDeviceOverviewKey, RecommendationValue } from './types';

type RecommendationsForDeviceOverview = Record<
  RecommendationForDeviceOverviewKey,
  {
    deviceIds: number[];
    shouldRecommend: (device: DeviceOverview) => boolean;
  }
>;

/**
 * We call this utility inside 'getDeviceOverviewService' to keep track
 * of the device recommendations and what device platforms
 * are currently connected to the cloud.
 */
export function manageDeviceRecommendations(): {
  setDeviceRecommendations: (device: DeviceOverview) => void;
  syncRecommendationStore: () => void;
} {
  const devicesPlatformsCopy = {
    ...devicePlatforms,
  };

  const recommendations: RecommendationsForDeviceOverview = {
    apPsk: {
      deviceIds: [],
      shouldRecommend: d => d.apPsk === '',
    },
    managePassword: {
      deviceIds: [],
      shouldRecommend: d => !d.managePassword,
    },
    layoutCalendarRoom: {
      deviceIds: [],
      shouldRecommend: d => d.layoutCalendarRoom === '',
    },
  };

  const setDeviceRecommendations = (device: DeviceOverview): void => {
    for (const recommendation of Object.values(recommendations)) {
      if (recommendation.shouldRecommend(device)) {
        recommendation.deviceIds.push(device.id);
      }
    }

    // This value for a given platform is true if defined at least once, so it doesn't
    // matter if we override the value on the next iteration
    devicesPlatformsCopy[device.platform] = true;
  };

  const syncRecommendationStore = () => {
    for (const [recommendationKey, { deviceIds }] of Object.entries(recommendations)) {
      updateRecommendation(recommendationKey as RecommendationForDeviceOverviewKey, {
        isInit: true,
        deviceIds,
      });
    }

    setDevicePlatforms(devicesPlatformsCopy);
  };

  return {
    setDeviceRecommendations,
    syncRecommendationStore,
  };
}

export function sortRecommendationByPriority(
  rA: RecommendationValue,
  rB: RecommendationValue
): number {
  return recommendationPriorityMap[rB.priority] - recommendationPriorityMap[rA.priority];
}

export function isRecommendationVisible(r: RecommendationValue): boolean {
  return !!(!r.dismissed && r.canBeVisible && (r.deviceIds?.length || !r.deviceIds));
}
