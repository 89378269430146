import { createZustandStore } from 'src/utils/zustand';
import { devicePlatforms } from '../constants';
import { RecommendationKey, RecommendationPriority, RecommendationValue } from '../types';
import { isRecommendationVisible } from '../utils';
import { RecommendationStoreState } from './types';
import { mergeRecommendationState } from './utils';

const recommendationDefaults: RecommendationValue = {
  key: 'apPsk',
  isInit: false,
  dismissed: false,
  canBeVisible: true,
  priority: RecommendationPriority.low,
};

const initialState: RecommendationStoreState = {
  isInit: false,
  hasRecommendations: false,
  hasDismissedRecommendations: false,
  devicePlatforms,
  recommendations: {
    apPsk: {
      ...recommendationDefaults,
      key: 'apPsk',
      deviceIds: [],
      priority: RecommendationPriority.high,
    },
    managePassword: {
      ...recommendationDefaults,
      key: 'managePassword',
      deviceIds: [],
      priority: RecommendationPriority.high,
    },
    layoutCalendarRoom: {
      ...recommendationDefaults,
      key: 'layoutCalendarRoom',
      deviceIds: [],
      priority: RecommendationPriority.medium,
    },
    connectCalendar: {
      ...recommendationDefaults,
      key: 'connectCalendar',
      priority: RecommendationPriority.medium,
    },
  },
};

export const { useStore: useRecommendationStore, createAction } =
  createZustandStore<RecommendationStoreState>('RecommendationStore', initialState, {
    persistOptions: {
      name: 'recommendations',
      // Sync dismissed state from localstorage
      merge: mergeRecommendationState,
    },
  });

export function setDevicePlatforms(
  currentDevicePlatforms: RecommendationStoreState['devicePlatforms']
): void {
  const setDevicePlatformsAction = createAction('setDevicePlatforms', state => {
    state.devicePlatforms = currentDevicePlatforms;
  });

  setDevicePlatformsAction();
}

export function updateRecommendation(
  recommendationKey: RecommendationKey,
  recommendationValue: Partial<RecommendationValue>
): void {
  const updateRecommendationAction = createAction('updateRecommendation', state => {
    state.recommendations[recommendationKey] = {
      ...state.recommendations[recommendationKey],
      ...recommendationValue,
    };

    // Update 'computed' values
    state.isInit = Object.values(state.recommendations).every(r => r.isInit);
    state.hasRecommendations = Object.values(state.recommendations).some(isRecommendationVisible);
    state.hasDismissedRecommendations = Object.values(state.recommendations).some(
      ({ dismissed }) => dismissed
    );
  });

  updateRecommendationAction();
}
