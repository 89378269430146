import { DevicePlatform } from 'src/typings/Device';
import { RecommendationPriority } from './types';

export const block = 'recommendation';

export const devicePlatforms: Record<DevicePlatform, boolean> = {
  DG1: false,
  DG2: false,
  DG3: false,
  CD1: false,
  VA_WIN: false,
  VA_ANDROID: false,
  VA_ANDROID_SMART: false,
};

export const recommendationPriorityMap: Record<RecommendationPriority, number> = {
  high: 3,
  medium: 2,
  low: 1,
};
