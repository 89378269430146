import { DeviceOverview } from 'src/typings/DeviceOverview';
import { ValueOf } from 'src/typings/global';

export const RecommendationPriority = {
  high: 'high',
  medium: 'medium',
  low: 'low',
} as const;
export type RecommendationPriority = ValueOf<typeof RecommendationPriority>;

export type RecommendationForDeviceOverviewKey = keyof Pick<
  DeviceOverview,
  'apPsk' | 'managePassword' | 'layoutCalendarRoom'
>;

export type RecommendationKey = RecommendationForDeviceOverviewKey | 'connectCalendar';

export type RecommendationValue = {
  key: RecommendationKey;
  // Track if all data is initialized to avoid flickering while calculating
  // the priority for each recommendation item
  isInit: boolean;
  dismissed: boolean;
  deviceIds?: number[];
  canBeVisible: boolean;
  priority: RecommendationPriority;
};

export const RecommendationIconPriority = {
  ...RecommendationPriority,
  none: 'none',
} as const;

export type RecommendationIconPriority = ValueOf<typeof RecommendationIconPriority>;
