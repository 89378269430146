import { useEffect, useMemo, useState } from 'react';
import { EventHandler, OpenFeature, ProviderEvents } from '@openfeature/web-sdk';
import { ValueOf } from 'src/typings/global';

export const FeatureFlag = {
  SIGNAGE_CONTENT: 'SIGNAGE_CONTENT',
  MASS_DEPLOYMENT_KEY: 'MASS_DEPLOYMENT_KEY',
  CUSTOM_FIRMWARE_UPDATE: 'CUSTOM_FIRMWARE_UPDATE',
  ALLOW_INTERACTION: 'ALLOW_INTERACTION',
  BLUETOOTH_SETUP: 'BLUETOOTH_SETUP',
  RECOMMENDATIONS: 'RECOMMENDATIONS',
} as const;

export type FeatureFlag = ValueOf<typeof FeatureFlag>;

const useFeature = (flagKey?: FeatureFlag, defaultValue = false): boolean => {
  const client = useMemo(() => OpenFeature.getClient(), []);
  const [value, setValue] = useState<boolean>(() =>
    client.getBooleanValue(flagKey ?? '', defaultValue)
  );

  useEffect(() => {
    const handleConfigurationChanged: EventHandler<
      ProviderEvents.ConfigurationChanged
    > = params => {
      if (!params || !params.flagsChanged || !flagKey) {
        return;
      }

      if (params.flagsChanged.includes(flagKey)) {
        setValue(client.getBooleanValue(flagKey ?? '', defaultValue));
      }
    };

    client.addHandler(ProviderEvents.ConfigurationChanged, handleConfigurationChanged);

    return () =>
      client.removeHandler(ProviderEvents.ConfigurationChanged, handleConfigurationChanged);
  }, [flagKey]);

  if (!flagKey) {
    return true;
  }

  return value;
};

export default useFeature;
