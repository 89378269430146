import { WeekDaysType } from 'src/constants/weekdays';
import { ThumbnailImage } from 'src/typings/Device';
import { ValueOf } from 'src/typings/global';
import { RGBColor } from 'src/typings/Shared';
import { SubscriptionProductName } from 'src/typings/Subscription';
import type { LayoutSettings } from '@airtame/cloud-components';

export type DeviceOverviewApiModel = {
  groupId: number;
  groupName: string;
  organizationId: number;
  devices: DeviceApiModel[];
};

export type DeviceApiModel = {
  ap24Channel: number;
  ap24Enabled: boolean;
  ap52Channel: number;
  ap52Enabled: boolean;
  apPsk: string;
  // ManagePassword has a special behavior.
  // The Backend does not share the password with the Frontend
  // for security reasons, it only shares the parameter as boolean.
  // On the other hand, the backend expects a string as the payload for uploading deviceSettings
  // Sending a request with an empty string has the hidden behavior of disabling the setting, while
  // including a non-empty string means to enable the setting and use the string as the password.
  managePassword: boolean | string;
  deviceName: string;
  homescreenOrientation: HomescreenOrientationApiModel;
  id: number;
  isOnline: boolean;
  lastConnected: number;
  lastSeen: number;
  networkState: DeviceNetworkStateApiModel;
  platform?: DevicePlatformApiModel;
  state: DeviceStateApiModel;
  screenshotEnabled: boolean;
  subscriptionProducts: SubscriptionProductName[];
  trialProducts: SubscriptionProductName[];
  updateAvailable: boolean;
  updateChannel: FirmwareChannelApiModel;
  updateProgress: number;
  version: string;
  settingsState?: DeviceSettingsStateApiModel;
  mac: string;
  layoutCalendarRoom: LayoutSettings['layoutCalendarRoom'];
};

export type DeviceInfoApiModel = Omit<DeviceApiModel, 'screenshotEnabled'> & {
  airplayEnabled: boolean;
  googlecastEnabled: boolean;
  miracastEnabled: boolean;
  autoUpdate: boolean;
  autoUpdateHours?: ScheduleAutoUpdateWeekDaysApiModel;
  autoRestart?: ScheduleAutoRestartConfigApiModel;
  discoveryProtocol: string;
  ledEnabled: boolean;
  pincodeEnabled: boolean;
  pincodeOverrideEnabled: boolean;
  preferredClockFormat: '12h' | '24h' | '';
  resolution: string;
  // Note that if signageContent is missing it means that there is
  // no legacy signage nor new signage
  signageContent?: DeviceSignageContentApiModel;
  splashBgColor: RGBColor;
  splashTextColor: RGBColor;
  splashContent: SplashContentApiModel[];
  splashCustomText: string;
  splashLayout: SplashLayoutApiModel;
  timezone: string;
  wifiForwarding: boolean;
  standby?: ScheduleStandbyConfigApiModel;
  supportedResolutions: ResolutionApiModel[] | null;
  currentVideomode: VideoModeApiModel;
  supportedVideomodes: VideoModeApiModel[];
  serialNumber?: string;
  monitorInfo: Partial<MonitorInfoApiModel>;
  conferencing?: ConferencingApiModel;
  guestStreamingEnabled: boolean;
  guestStreamingCustomText: string;
  allowInteraction?: boolean;
} & LayoutSettingsApiModel;

export type LayoutSettingsApiModel = LayoutSettings;

export type HomescreenOrientationApiModel = 'landscape' | 'portrait_left' | 'portrait_right';

export const NetworkFrequencyBandApiModel = {
  FREQUENCY_5GHZ: '5GHz',
  FREQUENCY_2GHZ: '2.4GHz',
} as const;

export type NetworkFrequencyBandApiModel = ValueOf<typeof NetworkFrequencyBandApiModel>;

export type DeviceNetworkInterfaceApiModel = {
  name: string;
  ip: string;
  mac: string;
  type: string;
  status?: string;
  frequency?: number;
  mode?: string;
  // eslint-disable-next-line camelcase
  signal_strength: number;
  ssid: string;
  band?: NetworkFrequencyBandApiModel;
};

export type DeviceNetworkStateApiModel = {
  online: boolean;
  interfaces: DeviceNetworkInterfaceApiModel[];
};

export const DevicePlatformApiModel = {
  DG1: 'DG1',
  DG2: 'DG2',
  DG3: 'DG3',
  CD1: 'CD1',
  VA_WIN: 'VA_WIN',
  VA_ANDROID: 'VA_ANDROID',
  VA_ANDROID_SMART: 'VA_ANDROID_SMART',
} as const;

export type DevicePlatformApiModel = ValueOf<typeof DevicePlatformApiModel>;

export type VirtualDevicePlatformApiModel = ValueOf<{
  [K in DevicePlatformApiModel as K extends `VA_${string}` ? K : never]: K;
}>;

export const DeviceStateApiModel = {
  idle: 'DMGR_IDLE',
  streaming: 'DMGR_APP',
  updating: 'DMGR_UPDATING',
  rebooting: 'DMGR_REBOOTING',
  resetting: 'DMGR_RESETTING',
  standby: 'DMGR_STANDBY',
} as const;

export const FirmwareChannelApiModel = {
  ga: 'ga',
  alpha: 'alpha',
  beta: 'beta',
  nightly: 'nightly',
} as const;

export type FirmwareChannelApiModel = ValueOf<typeof FirmwareChannelApiModel>;

export type DeviceStateApiModel = ValueOf<typeof DeviceStateApiModel>;

export const DeviceSettingsStateApiModel = {
  PENDING: 'pending',
  SYNCED: 'synced',
  ERROR: 'error',
  APPLYING: 'applying',
} as const;

export type DeviceSettingsStateApiModel = ValueOf<typeof DeviceSettingsStateApiModel>;

export type ResolutionApiModel = {
  mode: string;
  audio: boolean;
};

export type VideoModeApiModel = {
  audio: boolean;
  mode: string;
};

export type DevicePeripheralsApiModel = Record<PeripheralTypesApiModel, string[]>;

export type PeripheralSettingApiModel =
  | PeripheralSettingAutoApiModel
  | PeripheralSettingSpecificApiModel;

export type PeripheralSettingAutoApiModel = {
  type: 'auto';
};

export type PeripheralSettingSpecificApiModel = { type: 'specific'; name: string };

export type PeripheralTypesApiModel = 'audioinput' | 'audiooutput' | 'videoinput';

export type ConferencingApiModel = Partial<
  Record<PeripheralTypesApiModel, PeripheralSettingApiModel>
>;

export type DeviceSignageContentApiModel = {
  // The id has a double meaning here:
  // - 'legacy' means that is the id of a playlist
  // - 'signage' means that is the id of a signage template
  id: number;
  type: 'legacy' | 'signage';
};

export type SplashContentTypeApiModel = 'default' | 'image' | 'url' | 'app';

export type SplashContentApiModel = {
  type: SplashContentTypeApiModel;
  content: string | ArrayBuffer | null;
};

export const SplashLayoutApiModel = {
  FULL: 'full',
  MINIMAL: 'minimal',
  SIDE: 'side',
  NONE: 'none',
  DEFAULT: 'default',
} as const;

export type SplashLayoutApiModel = ValueOf<typeof SplashLayoutApiModel>;

export type MonitorInfoApiModel = {
  manufacturerName: string;
  monitorName: string;
  productCode: number;
  serialNumber: number;
};

export type ScheduleWeekDaysPropsApiModel = {
  on: string;
  off: string;
};

export type ScheduleAutoUpdateWeekDaysApiModel = Record<
  WeekDaysType,
  ScheduleWeekDaysPropsApiModel[]
>;

export const ScheduleAutoRestartModeApiModel = {
  Disabled: 'disabled',
  Scheduled: 'scheduled',
} as const;

export type ScheduleAutoRestartModeApiModel = ValueOf<typeof ScheduleAutoRestartModeApiModel>;

export type ScheduleAutoRestartDisabledConfigApiModel = {
  mode: typeof ScheduleAutoRestartModeApiModel.Disabled;
};

export type ScheduleAutoRestartScheduledConfigApiModel = {
  mode: typeof ScheduleAutoRestartModeApiModel.Scheduled;
  scheduled: ScheduleAutoUpdateWeekDaysApiModel;
};

export type ScheduleAutoRestartConfigApiModel =
  | ScheduleAutoRestartDisabledConfigApiModel
  | ScheduleAutoRestartScheduledConfigApiModel;

export const ScheduleStandbyModeApiModel = {
  Disabled: 'disabled',
  Scheduled: 'scheduled',
  Automatic: 'automatic',
  Instant: 'instant',
} as const;

export type ScheduleStandbyModeApiModel = ValueOf<typeof ScheduleStandbyModeApiModel>;

export type ScheduleStandbyDisabledConfigApiModel = {
  mode: typeof ScheduleStandbyModeApiModel.Disabled;
};

export type ScheduleStandbyScheduledConfigApiModel = {
  mode: typeof ScheduleStandbyModeApiModel.Scheduled;
  scheduled: Record<WeekDaysType, ScheduleStandbyWeekDaysPropsApiModel[]>;
};

export type ScheduleStandbyWeekDaysPropsApiModel = {
  active: string;
  standby: string;
};

export type ScheduleStandbyAutomaticConfigApiModel = {
  mode: typeof ScheduleStandbyModeApiModel.Automatic | typeof ScheduleStandbyModeApiModel.Instant;
  automatic?: number;
};

export type ScheduleStandbyConfigApiModel =
  | ScheduleStandbyDisabledConfigApiModel
  | ScheduleStandbyScheduledConfigApiModel
  | ScheduleStandbyAutomaticConfigApiModel;

export type CloudDeviceSettingsApiModel = {
  screenshotEnabled: boolean;
};

export type DeviceGetTokenApiModel = { token: string; id: number };

export type PostDeviceOOBSettingsApiRequest = {
  pairingCode: string;
  settings: { cloudToken: string } & Partial<DeviceInfoApiModel>;
};

export type PostDeviceOOBSettingsApiResponse = {
  macAddress: string;
  platform: DevicePlatformApiModel;
  firmwareVersion: string;
  deviceUuid: string;
};

// Response 404:
//    - It means that the device doesn't have a license assigned
//      to it or is activated in a different organization
export type GetDeviceActivationApiResponse = {
  plan: DeviceActivationPlanApiModel;
  // For 'pending' and 'in-progress' status we can try to fetch again since the
  // license is still being activated
  status?: DeviceActivationStatusApiModel;
  // If deviceId is different from the deviceId returned by device OOB endpoint
  // it means that the device license has already been consumed,
  // but (probably) after a factory reset we're adding it again,
  // since the license cannot be consumed twice the user
  // has to remove the previously added device
  // to assign the license again
  deviceId?: number;
};

export type DeviceActivationPlanApiModel = 'ineligible' | 'plus' | 'plus3y' | 'rooms' | 'edu';
export type DeviceActivationStatusApiModel = 'pending' | 'in-progress' | 'activated' | 'failed';

export type DeviceVersionStatusApiModel = {
  released: string;
  staging: string;
  cup: number;
};

export type DeviceVersionApiModel = Record<FirmwareChannelApiModel, DeviceVersionStatusApiModel>;

export type DeviceLatestVersionApiModel = Record<
  DevicePlatformApiModel,
  Partial<DeviceVersionApiModel>
>;

export type DeviceCommandApiModel = 'reboot' | 'update' | 'stop-stream';

export type PostDeviceCommandApiResponse = {
  successes: number[];
  errors: { deviceId: number; message: string; code?: number }[];
};

export type GetDeviceLatestFirmwareVersionApiModel = Omit<
  DeviceLatestVersionApiModel,
  VirtualDevicePlatformApiModel
> &
  Record<VirtualDevicePlatformApiModel, { latest: DeviceVersionStatusApiModel }>;

export type ThumbnailApiModel = {
  [key in number]: ThumbnailImage;
};
