import { RecommendationStoreState } from './types';

/**
 * We're keeping the recommendation state in sync with localstorage,
 * but since data changes constantly we ignore most of it,
 * what we really care to store is the 'dismissed' property.
 */
export function mergeRecommendationState(
  persistedState: RecommendationStoreState | undefined,
  currentState: RecommendationStoreState
): RecommendationStoreState {
  if (!persistedState) {
    return currentState;
  }

  for (const [key, value] of Object.entries(currentState.recommendations)) {
    currentState.recommendations[key] = {
      ...value,
      dismissed: persistedState.recommendations[key]?.dismissed ?? value.dismissed,
    };
  }

  return currentState;
}
