import { useEffect } from 'react';
import { useGetCalendarConnections } from 'src/queries/organization/useGetCalendarConnections';
import { useAuthStore } from 'src/store/authStore';
import { DevicePlatform } from 'src/typings/Device';
import { updateRecommendation, useRecommendationStore } from './recommendationStore';

export function useSyncRecommendationStoreForCalendarConnection(): void {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  const devicePlatforms = useRecommendationStore(state => state.devicePlatforms);

  const {
    data: { activeConnections },
    isFetched,
  } = useGetCalendarConnections({
    options: {
      enabled: isAuthenticated,
    },
  });

  useEffect(() => {
    const hasCalendars = !!Object.keys(activeConnections).length;

    updateRecommendation('connectCalendar', {
      isInit: isFetched,
      canBeVisible: !hasCalendars,
    });

    const canLayoutCalendarRoomBeVisible =
      // No point in showing 'layoutCalendarRoom' if we have no connected calendars
      hasCalendars &&
      // only visible when we have SMART devices attached to the organization
      devicePlatforms[DevicePlatform.VA_ANDROID_SMART];

    updateRecommendation('layoutCalendarRoom', {
      canBeVisible: canLayoutCalendarRoomBeVisible,
    });
  }, [activeConnections, isFetched, devicePlatforms]);
}
