import { ValueOf } from 'src/typings/global';

export const SideNavKey = {
  devices: 'devices',
  signage: 'signage',
  recommendation: 'recommendation',
  update: 'update',
  organizationSettings: 'organizationSettings',
  profile: 'profile',
} as const;

export type SideNavKey = ValueOf<typeof SideNavKey>;
