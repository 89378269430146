import {
  DeviceOverview,
  DeviceOverviewById,
  UpdatedDeviceStatus,
} from 'src/typings/DeviceOverview';
import { deviceSettingsKeys } from './constants';

export function getDeviceOverviewKey(): string[] {
  return [deviceSettingsKeys.deviceOverview];
}

export function getDeviceSettingsKey(deviceIds: number[]): (string | { deviceIds: number[] })[] {
  return [deviceSettingsKeys.deviceSettings, { deviceIds }];
}

export function getDeviceLatestFirmwareVersions(): string[] {
  return [deviceSettingsKeys.deviceLatestFirmwareVersions];
}

export function getDevicePeripheralsKey(deviceId?: number): (string | { deviceId: number })[] {
  if (deviceId) {
    return [deviceSettingsKeys.devicePeripherals, { deviceId }];
  }

  return [deviceSettingsKeys.devicePeripherals];
}

export function getDeviceActivationKey(
  macAddress?: string,
  platform?: string
): (string | { macAddress: string; platform: string })[] {
  if (macAddress && platform) {
    return [deviceSettingsKeys.getDeviceActivationStatus, { macAddress, platform }];
  }

  return [deviceSettingsKeys.getDeviceActivationStatus];
}

export function getDeviceSettingsForSignageKey(
  deviceIds: number[]
): (string | { deviceIds: number[] })[] {
  return [deviceSettingsKeys.deviceSettingsForSignage, { deviceIds }];
}

export function getDeviceScreenshotKey(
  deviceId: number,
  isFullScreen: boolean
): [string, { deviceId: number; isFullScreen: boolean }] {
  return [deviceSettingsKeys.deviceScreenshot, { deviceId, isFullScreen }];
}

/**
 * Filter error devices by a given list of ids
 */
export function getErrorDevices(
  devicesById: DeviceOverviewById,
  errors: Record<string, unknown>[]
): { ids: number[]; devices: DeviceOverview[] } {
  const ids = errors.filter(error => error.code !== 504).map(data => data.deviceId) as number[];
  const devices = ids.reduce<DeviceOverview[]>((acc, deviceId) => {
    if (devicesById[deviceId]) {
      acc.push(devicesById[deviceId]);
    }
    return acc;
  }, []);
  return { ids, devices };
}

export const updateDevicesData = (
  devicesById: DeviceOverviewById,
  deviceToBeUpdatedIds: number[],
  overrides?: (device: DeviceOverview) => Partial<
    Omit<DeviceOverview, 'status'> & {
      status: UpdatedDeviceStatus;
    }
  >
): DeviceOverviewById => {
  const updatedDevicesById = deviceToBeUpdatedIds.reduce<DeviceOverviewById>((acc, deviceId) => {
    const device = devicesById[deviceId];
    acc[deviceId] = {
      ...device,
      ...overrides?.(device),
    } as DeviceOverview;
    return acc;
  }, {});

  return { ...devicesById, ...updatedDevicesById };
};
